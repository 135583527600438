import React from "react"
import logo_large from '../images/logo_lg.png';

const IndexPage = () =>
    <>
        <img src={logo_large} style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%"
        }}/>
    </>;

export default IndexPage;